import { Link } from 'react-router-dom'
import Button from 'components/forms/Button'
import NavBar from 'components/navbar/NavBar'
import Footer from '../components/footer/Footer'

import Header from 'components/typography/Header'

import styles from './styles/Error.css'

const errorMessages = {
  404: 'Sorry, we couldn’t find that.',
  401: 'You need to be logged in to see this.',
  403: 'Hey, no peeking!'
}

export const Error = ({ status }) => (
  <div>
    <NavBar staticPage />
    <div className={styles.error}>
      {status && <Header level={2}>{status}</Header>}
      <Header level={2}>{errorMessages[status] || 'Something went wrong!'}</Header>
      <img alt='skeptical pete' className={styles.errorImage} src={`${CONFIG.assetsBaseURL}/images/pete-skeptical.png`} />
      <div className={styles.buttonLayout}>
        <Link to='/'>
          <Button className={styles.button} color='blue'>Visit homepage</Button>
        </Link>
      </div>
    </div>
    <Footer />
  </div>
)

export default Error
